export const searchTasksWithPagination = async (query, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, next_page_key=0, page_size=20, favorited = '', show_all_tasks, wsid, pending) => {
    query = query || "";
    tag = tag || "";
    userid = userid || "";
    collaborator = collaborator || "";
    url_prefix = url_prefix || "";
    order_by = order_by || "";
    with_pending_perms = with_pending_perms || false;
    favorited = favorited || '';

    url_prefix = url_prefix.replace(/\/+$/,'');

    let response;

    let url = '';
    if (pending == 'true') {
      url  = getUrl(url_prefix + '/api/tasks/?' +'&page_key=' + next_page_key + '&page_size='+page_size + '&workspace_approval_state=pending')
    } else {
      url  = getUrl(url_prefix + '/api/tasks/?with_pending_perms=' + with_pending_perms+'&page_key=' + next_page_key + '&page_size='+page_size)
    }
    
    if (query.length > 0) {

      const route = useRoute();

      let vectorized_search = '';
      if (typeof(window) != 'undefined') {
        vectorized_search = localStorage.getItem('VSearchParams');
        if (vectorized_search == null) {
          vectorized_search = '&knn.k=3&knn.nc=10';
        }
      } else if (route.query.hasOwnProperty('autocomplete')) {
        vectorized_search = '&knn.k=3&knn.nc=10';
      }
  
      url += "&q=" + encodeURI(query) + vectorized_search;  
    }
    
    if (tag.length > 0) {
      url += "&tags=" + encodeURI(tag)
    }
    if (userid.length > 0) {
      url += "&userid=" + userid
    }
    if (collaborator.length > 0) {
      url += "&collaborator=" + collaborator
    }
    
    if (order_by.length == 0) {
      order_by = "elastic";
    }

    if (favorited == 'true') {
      url += '&favorited=true&userid=me';
      order_by = '';
    }

    if (order_by.length > 0) {
      url += "&order_by=" + order_by
    }

    if ((show_all_tasks == true) || (query != '') || (tag.length > 0) || (collaborator.length > 0) || (userid.length > 0) || (favorited == 'true') || (pending == 'true')) {
      url += ""
    } else {
      url += "&not_parent_count=1"
    }

    if ((wsid != '') && (typeof(wsid) != 'undefined')) {
      url += "&workspace_id=" + wsid;
    }
    response = await logFetchAJAX(url,{});      
    return response
}
