/*
On the front-page, we have infinite scroll.  As the user scroll down the page, we load and 
display more tasks.  That goes through this function.  When the user search using the top 
search box, it also go through this function.  We use the pagination property to track 
pagination for the homepage infinite scroll.  We use the paginationSuggestion property 
to track pagination for infinite scroll for the top search box.

In this file, 'Suggestion' means that the search is being done using the search box at the top.
*/

export const useSearchStore = defineStore("SearchStore", {
  state: () => ({
    searchType: null,
    pagination: {next_page_key: 0, has_more_results: true},
    paginationSuggestion: {next_page_key: 0, has_more_results: true}, 
    community_pagination: {next_page_key: 0, has_more_results: true},
    community_paginationSuggestion: {next_page_key: 0, has_more_results: true},
    pageSize: 20,
    searchData: [],
    searchSuggestion: [],
  }),
  getters: {
    getSearchResult() {
      return this.searchData;
    },
    getSearchSuggestion() {
      return this.searchSuggestion;
    },
    getSearchType() {
      return this.searchType;
    },
    getSearchPagination() {
      if (this.searchType === 'search') {
        //console.log("searchType:", this.searchType, "pagination:", this.pagination)
        return this.pagination;
      } else {
        //console.log("searchType:", this.searchType, "paginationSuggestion:", this.paginationSuggestion)
        return this.paginationSuggestion;
      }
    },
    getCommunitySearchPagination() {
      if (this.searchType === 'search') {
        return this.community_pagination;
      } else {
        return this.community_paginationSuggestion;
      }
    }
  },
  actions: {
    async doSearch(query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, include_community, favorited, show_all_tasks, wsid, pending) {
      let page_size = this.pageSize;
      if (include_community) {
        page_size = 10;
      }

      /*
      Currently, the global search functionality is not yet ready from the back-end, so we have to do two separate 
      calls.  First, do local search using the searchTasksWithPagination composable.  After that is done, do the 
      'community' search using the same composable.

      When the global search functionality is ready from the back-end, we need to:
      1. Create a new composable named globalSearchTasksWithPagination based from searchTasksWithPagination.
         We just have to change the endpoint.
      2. Use an if condition.  If include_community is false, call the existing searchTasksWithPagination composable.
         If include_community is true, call the new globalSearchTasksWithPagination composable.
         The rest regarding updating searchSuggestion, paginationSuggestion, etc, should stay the same.
         Of course, this depends on whether the global search endpoint is compatible with the existing endpoint or not.

      The term 'global search' mean 'includes community'.
      
      For home page pagination, we do not include task from community.  When the user search using the top search box, 
      we includes tasks from community.  

      When the user search using the top search box, whether we include tasks from community is also controlled by the 
      flag in the integration page.
      */

      if (include_community) {
        // Do local search
        let search_pagination = this.getSearchPagination;
        let next_page_key = search_pagination.next_page_key;  
        let local_promise = undefined;
        if (search_pagination.has_more_results) {
          local_promise = searchTasksWithPagination(query_string, tag, userid, collaborator, '', order_by, with_pending_perms, next_page_key, page_size, favorited, show_all_tasks, wsid, pending);
        } else {
          local_promise = Promise.resolve({});
        }

        // Do community search
        let community_search_pagination = this.getCommunitySearchPagination;
        let community_next_page_key = community_search_pagination.next_page_key;
        let community_promise = undefined;
        if (community_search_pagination.has_more_results) {
          community_promise = searchTasksWithPagination(query_string, tag, userid, collaborator, url_prefix, order_by, with_pending_perms, community_next_page_key, page_size, favorited, show_all_tasks, wsid, pending);
        } else {
          community_promise = Promise.resolve({});
        }
        //await Promise.all([local_promise, community_promise]);
        let local_data = await local_promise;
        let community_data = await community_promise;

        let local_tasks = [];
        let community_tasks = [];

        // Track pagination properties for local
        if (Object.keys(local_data).length > 0) {
          local_tasks = [... local_data.tasks];
          if (this.searchType === 'search_suggestion') {
            this.paginationSuggestion = local_data.pagination;
          }
          if (this.searchType === 'search') {
            this.pagination = local_data.pagination
          }
        }
        
        // Track pagination properties for community
        if (Object.keys(community_data).length > 0) {
          community_data.tasks.map((task) => {
            task['from_community'] = true;
          })
          community_tasks = [... community_data.tasks];
          if (this.searchType === 'search_suggestion') {
            this.community_paginationSuggestion = community_data.pagination;
          }
          if (this.searchType === 'search') {
            this.community_pagination = community_data.pagination
          }
        }

        // Merge local_tasks and community_tasks together
        let merged_tasks = this.mergeLocalAndCommunity(local_tasks, community_tasks)

        // We already handle pagination properties above.
        // Now we just have to handle the rest.
        if (this.searchType === 'search_suggestion') {
          //this.searchSuggestion = [...this.searchSuggestion , ...merged_tasks];
          this.searchSuggestion = [...new Set([...this.searchSuggestion , ...merged_tasks])]
        }
        if (this.searchType === 'search') {
          //this.searchData = [...this.searchData, ...merged_tasks];
          this.searchData = [...new Set([...this.searchData, ...merged_tasks])];
          this.searchSuggestion = []
        }
      } else {
        let search_pagination = this.getSearchPagination;
        let next_page_key = search_pagination.next_page_key;  
        if (search_pagination.has_more_results) {
          let data = await searchTasksWithPagination(query_string, tag, userid, collaborator, '', order_by, with_pending_perms, next_page_key, page_size, favorited, show_all_tasks, wsid, pending);
          if (data) {
            if (this.searchType === 'search_suggestion') {
              //this.searchSuggestion = [...this.searchSuggestion , ...data.tasks];
              this.searchSuggestion = [...new Set([...this.searchSuggestion , ...data.tasks])]

              this.paginationSuggestion = data.pagination;
            }
            if (this.searchType === 'search') {
              //this.searchData = [...this.searchData, ...data.tasks]
              this.searchData = [...new Set([...this.searchData, ...data.tasks])]
              this.pagination = data.pagination
              this.searchSuggestion = []
            }
          }
        }
      }
    },
    setSearchData(data) {
      //console.log("searchStore.js ~ setSearchData ~ searchData:", data)
      this.searchData = data
    },
    setSearchSuggestion(data) {
      this.searchSuggestion = data
    },
    setSearchType(type) {
      this.searchType = type
    },
    setSearchPagination(pagination) {
      if (this.searchType === 'search') {
        this.pagination = pagination;
      } else {
        this.paginationSuggestion = pagination;
      }
    },
    setCommunitySearchPagination(pagination) {
      if (this.searchType === 'search') {
        this.community_pagination = pagination;
      } else {
        this.community_paginationSuggestion = pagination;
      }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    
    mergeLocalAndCommunity(local_tasks, community_tasks) {
      //let results = [...community_tasks ,...local_tasks];
      //console.log("LOCAL LENGTH:", local_tasks.length, "COMMUNITY LENGTH:", community_tasks.length)
      let results = [];
      while((local_tasks.length > 0) || (community_tasks.length > 0)) {
        if (local_tasks.length > 0) {
          let random_number = this.getRandomInt(1, Math.min(5, local_tasks.length));
          for (let i = 0; i < random_number; i++) {
            let task = local_tasks.shift();
            results.push(task);
          }
        }

        if (community_tasks.length > 0) {
          let random_number = this.getRandomInt(1, Math.min(5,community_tasks.length));
          for (let i = 0; i < random_number; i++) {
            let task = community_tasks.shift();
            results.push(task);
          }
        }
      }

      return results;
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot));
}
