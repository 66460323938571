export const isEnabledShowSearchResultFromCommunity = async (store) => {
    if (! isAuthenticated()) {
      return false;
    }
    
    if (Object.keys(store.getSetting).length === 0) {
      await store.setSetting(null)
    }

    let show_search_result_from_community = false;
    let settings = store.getSetting;

    let community_url = getCommunityURL();
    if ((typeof(community_url) == 'undefined') || (community_url == null) || (community_url == '')) {
      return false;
    }

    if (
      (settings.hasOwnProperty('admin_settings')) && 
      (settings.admin_settings.hasOwnProperty('flag_enable_dagknows_community')) &&
      (settings.admin_settings.flag_enable_dagknows_community) &&
      (settings.hasOwnProperty('dagknows_community_credentials')) &&
      (settings.dagknows_community_credentials.hasOwnProperty('enable_download'))
    ) {
      show_search_result_from_community = settings.dagknows_community_credentials.enable_download;
    }


    return show_search_result_from_community;
}
